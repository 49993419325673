import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'common/redux'
import type { Middleware } from 'redux'
import createAPI from './api'
import { Training } from './training'

export type SessionPlanningState = {
	training?: Training
}

const initialState: SessionPlanningState = {}

type SessionPlanningAction = {
	type: 'TRAINING_UPDATED'
	training: Training
}

export const trainingUpdated = (training: Training): SessionPlanningAction => ({
	type: 'TRAINING_UPDATED',
	training,
})

const fetchSessionByLiveSessionId = createAsyncThunk<
	Training | null,
	string,
	{ state: RootState }
>(
	'training/fetchSessionByLiveSessionId',
	async (id: string) => {
		const api = createAPI()

		return api.fetchSessionByLiveId(id)
	},
	{
		condition: (_, { getState }) => !getState().sessionInfo.training,
	}
)

export function reducer(
	state: SessionPlanningState = initialState,
	action: SessionPlanningAction
): SessionPlanningState {
	switch (action.type) {
		case 'TRAINING_UPDATED':
			return {
				...state,
				training: action.training,
			}
		default:
			return state
	}
}

const selectTraining = (state: SessionPlanningState): Training | undefined => {
	return state.training
}

const trainingApi = createAPI()
//Middleware
export const middleware: Middleware = () => next => action => {
	if (action.type === 'EVENTBUS/SESSION_UPDATED') {
		const { id } = action.payload

		trainingApi.fetchSession(id).then(session => {
			if (session) {
				next(trainingUpdated(session))
			}
		})
	}

	next(action)
}

//selectors
export { selectTraining }

//actions
export { fetchSessionByLiveSessionId }
