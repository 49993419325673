import { colors, ui, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { FC } from 'react'

const Wrapper = styled(ui.Flex)`
	align-items: center;
	column-gap: 10px;
	position: relative;
	margin-bottom: 10px;
	padding: 10px 10px 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(38, 51, 77, 0.1);
	border-radius: 11px;
`

const CircleIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.blueGroup};
	border-radius: 50%;
	width: 32px;
	height: 32px;
	z-index: 1;

	svg {
		max-width: 15px;
		max-height: 16px;
		fill: ${colors.white};
	}
`

type Props = {
	icon: JSX.Element
}

export const NoCameraDevice: FC<Props> = ({ icon }) => {
	return (
		<Wrapper>
			<CircleIcon>{icon}</CircleIcon>
			<div>
				<intl.Translate>hardware_check.select_camera.none</intl.Translate>
			</div>
		</Wrapper>
	)
}
