import { eventbus, security, ui } from '@beelday/common'
import { Config } from 'common/config'
import ConnectionHealthMonitor from 'connectivity/connection-health-monitor'
import { EventSourceManager } from 'event-source-manager/event-source-manager'
import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { useDispatch } from 'app-redux'
import {
	Route,
	RouteProps,
	BrowserRouter as Router,
	Switch,
	useParams,
} from 'react-router-dom'
import useTrainingAPI from 'session-info/api/hooks'
import {
	fetchSessionByLiveSessionId,
	trainingUpdated,
} from 'session-info/redux'
import { setToken } from 'session/redux'
import ExitToSessionPlanning from 'workflow/exit-to-session-planning'
import { JoinableWorkflow } from 'workflow/joinable-workflow'
import PreparationScreen from 'workflow/presentation/preparation-screen'
import { setWorkflowId } from 'workflow/redux'
import { ReactAudioContext } from './features/user-engagement/audio-context-provider'

const WorkflowRoute = () => {
	const { initAudioContext } = useContext(ReactAudioContext)
	const { workflowId } = useParams<{ workflowId: string }>()
	const dispatch = useDispatch()

	const [entered, setEntered] = useState(false)

	const api = useTrainingAPI()

	const onEnter = () => {
		// Due to privacy concerns Chrome limits creating new AudioContext to event handlers only.
		// This is the only button user is forced to click in live-session therefore the only chance
		// to initialize the Web Audio API.
		try {
			initAudioContext()
		} catch (e) {}
		setEntered(true)
	}

	useEffect(() => {
		dispatch(fetchSessionByLiveSessionId(workflowId))
		api.fetchSessionByLiveId(workflowId).then(session => {
			if (session) {
				dispatch(trainingUpdated(session))
			}
		})

		if (entered) {
			dispatch(setWorkflowId(workflowId))
		}
	})

	if (!workflowId) {
		return null
	}

	if (!entered) {
		return <PreparationScreen onEnter={onEnter} />
	}

	return <JoinableWorkflow />
}

const ProvideToken = () => {
	const token = security.useToken()
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(setToken(token))
	}, [dispatch, token])

	return null
}

const ProtectedRoute: FC<RouteProps> = ({ children, ...rest }) => {
	const token = security.useToken()
	return (
		<Route
			{...rest}
			render={() =>
				token.type === 'VALID_TOKENS' ? (
					children
				) : token.type === 'INVALID_TOKENS' ? (
					(window.location.href = '/account/login')
				) : (
					<ui.FullScreenSpinner />
				)
			}
		/>
	)
}

const authConfig = {
	clientId: Config.AUTH_CLIENT_ID,
	authUrl: Config.AUTH_URL,
	authRealm: Config.AUTH_REALM,
}

export const RoutedApp: FC = () => {
	const dispatch = useDispatch()
	const handleBusEvent = useCallback(
		event => dispatch({ type: `EVENTBUS/${event.type}`, payload: event }),
		[dispatch]
	)
	return (
		<security.ProvideAuth config={authConfig}>
			<ProvideToken />
			<eventbus.ConnectEventBus eventHandler={handleBusEvent}>
				<Router>
					<Switch>
						<Route path="/404" component={ui.NotFound} />
						<ProtectedRoute path="/:workflowId">
							<WorkflowRoute />
							<EventSourceManager />
							<ConnectionHealthMonitor />
						</ProtectedRoute>
						<ProtectedRoute exact path="/">
							<ExitToSessionPlanning />
						</ProtectedRoute>
					</Switch>
				</Router>
			</eventbus.ConnectEventBus>
		</security.ProvideAuth>
	)
}
