import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { useSelector } from 'app-redux'
import { Config } from 'common/config'
import { Colors } from 'common/presentation/colors'
import { RootState } from 'common/redux'
import MicrophoneIcon from 'features/microphone/microphone-icon'
import { useOrganizationBranding } from 'organization/use-organization-branding'
import { FC, useEffect, useState } from 'react'
import { CameraOn } from 'room/common/presentation/icon/camera-on'
import { useMediaDevices } from 'utils/hooks/use-media-devices'
import AskForPermissionView from './ask-for-permission-view'
import {
	SafariNotSupportedInfo,
	useDetectSafari,
} from './safari-not-supported-info'
import SelectDevice from './select-device'
import VideoPreview from './video-preview'
import { NoCameraDevice } from './no-camera-device'

type Props = {
	onEnter: () => void
}

const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
	column-gap: 40px;
`

const Content = styled.div`
	background: ${Colors.white};
	border-radius: 20px;
	box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
	padding: 40px;
	max-width: 540px;
	width: 100%;
	flex-grow: 1;
	margin: auto auto;
`

const JoinButton = styled.div`
	text-align: right;
	margin-top: 40px;
`

const HardwareCheck: FC<Props> = ({ onEnter }) => {
	const {
		availableCameras,
		availableMicrophones,
		selectCamera,
		selectedMicrophone,
		selectMicrophone,
		stream,
		startStream,
		stopStream,
		selectedCamera,
		permissionsGranted,
		askForPermission,
		error,
	} = useMediaDevices()

	const [isCheckedPermission, setIsCheckedPermission] = useState(false)

	useEffect(() => {
		if (!permissionsGranted && !isCheckedPermission) {
			askForPermission().then(() => setIsCheckedPermission(true))
		}
	}, [
		permissionsGranted,
		askForPermission,
		setIsCheckedPermission,
		isCheckedPermission,
	])

	useEffect(() => {
		const defaultCamera = availableCameras.length
			? availableCameras[0].deviceId
			: undefined

		if (!selectedCamera && defaultCamera) {
			selectCamera(defaultCamera)
		}
	}, [availableCameras, selectedCamera, selectCamera])

	useEffect(() => {
		const defaultMicrophone = availableMicrophones.length
			? availableMicrophones[0].deviceId
			: undefined

		if (!selectedMicrophone && defaultMicrophone) {
			selectMicrophone(defaultMicrophone)
		}
	}, [availableMicrophones, selectedMicrophone, selectMicrophone])

	useEffect(() => {
		startStream()

		return () => {
			stopStream()
		}
	}, [selectedCamera, startStream, stopStream, askForPermission])

	const training = useSelector((state: RootState) => state.sessionInfo.training)

	const isSafari = useDetectSafari()
	const branding = useOrganizationBranding(training?.organizationId)

	return isCheckedPermission ? (
		<ContentWrapper>
			<ui.Flex style={{ width: '50%', margin: 'auto' }}>
				<Content>
					{permissionsGranted ? (
						<>
							<VideoPreview stream={stream} />
							{availableCameras.length > 0 ? (
								<SelectDevice
									devices={availableCameras}
									selected={selectedCamera}
									label={
										<intl.Translate>
											hardware_check.select_camera
										</intl.Translate>
									}
									icon={<CameraOn />}
									onChange={selectCamera}
								/>
							) : (
								<NoCameraDevice icon={<CameraOn />} />
							)}

							<SelectDevice
								devices={availableMicrophones}
								selected={selectedMicrophone}
								label={
									<intl.Translate>
										hardware_check.select_microphone
									</intl.Translate>
								}
								icon={<MicrophoneIcon />}
								onChange={selectMicrophone}
							/>
							{isSafari && <SafariNotSupportedInfo />}
							<JoinButton>
								<ui.ButtonPrimary
									onClick={onEnter}
									data-testid="session.device.confirm"
								>
									<intl.Translate>hardware_check.join</intl.Translate>
								</ui.ButtonPrimary>
							</JoinButton>
						</>
					) : (
						<AskForPermissionView
							onAskForPermission={askForPermission}
							error={error}
						/>
					)}
				</Content>
			</ui.Flex>
			{branding?.heroPhotoId && (
				<ui.Flex style={{ width: '50%' }}>
					<ui.CompanyHeroPhoto
						width="100%"
						height="100%"
						userApiUrl={Config.USER_API_URL}
						photoId={branding?.heroPhotoId}
						style={{
							backgroundColor: '#f8f8fb',
						}}
					/>
				</ui.Flex>
			)}
		</ContentWrapper>
	) : null
}

export default HardwareCheck
