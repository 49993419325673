import { intl } from '@beelday/common'
import { FC } from 'react'
import ChatNotifications from '../../messaging/chat-notifications'
import { AgendaIcon } from '../common/presentation/icon/agenda'
import { ChatIcon } from '../common/presentation/icon/chat-icon'
import { MenuToggleItem } from '../common/presentation/menu'
import {
	MiscSelectedSelectedItem,
	setMiscPanelOpen,
	setMiscPanelSelectedItem,
} from '../common/redux'
import { useDispatch, useSelector } from 'app-redux'
import { RootState } from '../../common/redux'
import { BoardletIcon } from '../common/presentation/icon/boardlet-icon'
import { WallIcon } from '../common/presentation/icon/wall-icon'

const MiscPanelMenu: FC = () => {
	const dispatch = useDispatch()
	const training = useSelector((state: RootState) => state.sessionInfo.training)
	const miscPanelSelectedItem = useSelector(
		(state: RootState) => state.room.miscPanel.selectedItem
	)
	const miscPanelOpen = useSelector(
		(state: RootState) => state.room.miscPanel.isOpen
	)

	return (
		<>
			<MenuToggleItem
				icon={
					training ? (
						<ChatNotifications
							trainingId={training.id}
							open={
								miscPanelSelectedItem === MiscSelectedSelectedItem.Messages &&
								miscPanelOpen
							}
						/>
					) : (
						<ChatIcon />
					)
				}
				active={
					miscPanelSelectedItem === MiscSelectedSelectedItem.Messages &&
					miscPanelOpen
				}
				onClick={() => {
					dispatch(setMiscPanelOpen(true))
					dispatch(setMiscPanelSelectedItem(MiscSelectedSelectedItem.Messages))
				}}
				label={<intl.Translate>lobby.misc.messages</intl.Translate>}
			/>
			<MenuToggleItem
				icon={<AgendaIcon />}
				active={
					miscPanelSelectedItem === MiscSelectedSelectedItem.Agenda &&
					miscPanelOpen
				}
				onClick={() => {
					dispatch(setMiscPanelOpen(true))
					dispatch(setMiscPanelSelectedItem(MiscSelectedSelectedItem.Agenda))
				}}
				label={<intl.Translate>lobby.misc.agenda</intl.Translate>}
			/>
			{training?.feedType && (
				<MenuToggleItem
					icon={training.feedType === 'WALL' ? <WallIcon /> : <BoardletIcon />}
					active={
						miscPanelSelectedItem === MiscSelectedSelectedItem.Boardlet &&
						miscPanelOpen
					}
					onClick={() => {
						dispatch(setMiscPanelOpen(true))
						dispatch(
							setMiscPanelSelectedItem(MiscSelectedSelectedItem.Boardlet)
						)
					}}
					label={
						<intl.Translate>{`lobby.misc.${training.feedType}`}</intl.Translate>
					}
				/>
			)}
		</>
	)
}

export default MiscPanelMenu
