import { colors, icons, intl } from '@beelday/common'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

const Container = styled.div`
	align-items: center;
	background-color: ${colors.indigoBlue10};
	border-radius: 11px;
	display: flex;
	padding: 20px 15px;
	width: 100%;
`

const Icon = styled.div`
	min-height: 40px;
	min-width: 40px;
	width: 40px;
	height: 40px;
	margin-right: 15px;

	svg {
		width: 100%;
		height: 100%;
	}
`

const Text = styled.div`
	width: 100%;
	font-size: 14px;
	text-align: left;
`

const A = styled.a`
	color: ${colors.indigoBlue};
	text-decoration: underline;
`

export const useDetectSafari = (): boolean => {
	const [isSafari, setIsSafari] = useState(false)

	const chrome = navigator.userAgent.indexOf('Chrome') > -1
	const firefox = navigator.userAgent.indexOf('Firefox') > -1
	const safari = navigator.userAgent.indexOf('Safari') > -1

	useEffect(() => {
		setIsSafari(safari && !(chrome && safari) && !(firefox && safari))
	}, [safari, firefox, chrome])

	return isSafari
}

export const SafariNotSupportedInfo = (): JSX.Element => (
	<Container>
		<Icon>
			<icons.SafariLogoIcon />
		</Icon>
		<Text>
			<strong>
				<intl.Translate>hardware_check.safari_user</intl.Translate>
			</strong>
			&nbsp;
			<intl.Translate>hardware_check.safari_user.description</intl.Translate>
			&nbsp;
			<A href="https://www.google.com/chrome/" target="_blank">
				<intl.Translate>
					hardware_check.safari_user.download_chrome
				</intl.Translate>
			</A>
		</Text>
	</Container>
)
