import { useState, useEffect } from 'react'

export type UseMediaDevices = {
	uiReady: boolean
	checkingPermissions: boolean
	permissionsGranted: boolean
	askForPermission: () => Promise<void>

	error?: MediaDevicesError

	stream?: MediaStream
	stopStream: () => void
	startStream: () => Promise<void>

	selectedCamera?: string
	selectedSpeaker?: string
	selectedMicrophone?: string

	unavailableCamera?: string
	unavailableSpeaker?: string
	unavailableMicrophone?: string

	selectCamera: (deviceId: string) => Promise<void>
	selectSpeaker: (deviceId: string) => Promise<void>
	selectMicrophone: (deviceId: string) => Promise<void>

	availableDevices: MediaDeviceInfo[]
	availableCameras: MediaDeviceInfo[]
	availableSpeakers: MediaDeviceInfo[]
	availableMicrophones: MediaDeviceInfo[]
}

export type MediaDevicesError =
	| 'LISTING_DEVICES_FAILED'
	| 'ASKING_FOR_PERMISSIONS_FAILED'
	| 'DEVICE_IS_IN_USE'
	| 'ASKING_FOR_PERMISSIONS_TIMED_OUT'
	| 'CAMERA_FAILED_TO_START'
	| 'UNKNOWN_ERROR'

const TIME_FOR_USER_DECISION = 5_000
const LS_CAMERA_KEY = 'selected-camera'
const LS_MICROPHONE_KEY = 'selected-microphone'
const LS_SPEAKER_KEY = 'selected-speaker'

let allDevices: MediaDeviceInfo[] = []
const failedDevices: MediaDeviceInfo[] = []
let availableDevices: MediaDeviceInfo[] = []
let availableCameras: MediaDeviceInfo[] = []
let availableSpeakers: MediaDeviceInfo[] = []
let availableMicrophones: MediaDeviceInfo[] = []

let selectedCamera: string | undefined =
	window.localStorage.getItem(LS_CAMERA_KEY) || undefined
let selectedMicrophone: string | undefined =
	window.localStorage.getItem(LS_SPEAKER_KEY) || undefined
let selectedSpeaker: string | undefined =
	window.localStorage.getItem(LS_MICROPHONE_KEY) || undefined

const allStreams = new Map<string, MediaStream | undefined>()
let stream: MediaStream | undefined = undefined
let error: MediaDevicesError | undefined = undefined

let uiReady = true
let checkingPermissions = false
let permissionsGranted = false

const subscriptions = new Set<() => void>()

const subscribe = (callback: () => void) => {
	subscriptions.add(callback)
	return () => {
		subscriptions.delete(callback)
	}
}

const notifySubscribers = (reason?: string) => {
	if (reason && subscriptions.size) {
		console.log(
			`MediaDevices | ${reason} (notifying ${subscriptions.size} components)`
		)
	}
	subscriptions.forEach(callback => callback())
}

const startAsyncOperation = (reason?: string) => {
	uiReady = false
	notifySubscribers(reason ? `Started ${reason}` : undefined)
}

const finishAsyncOperation = (reason?: string) => {
	uiReady = true
	notifySubscribers(reason ? `Completed ${reason}` : undefined)
}

const syncDevices = () => {
	availableDevices = allDevices.filter(
		device => !failedDevices.includes(device)
	)
	availableCameras = availableDevices.filter(
		device => device.kind === 'videoinput'
	)
	availableSpeakers = availableDevices.filter(
		device => device.kind === 'audiooutput'
	)
	availableMicrophones = availableDevices.filter(
		device => device.kind === 'audioinput'
	)

	selectedCamera = selectedCamera ?? availableCameras[0]?.deviceId
	selectedSpeaker = selectedSpeaker ?? availableSpeakers[0]?.deviceId
	selectedMicrophone = selectedMicrophone ?? availableMicrophones[0]?.deviceId

	if (
		selectedCamera &&
		!availableCameras.find(device => device.deviceId === selectedCamera)
	) {
		selectedCamera = undefined
		window.localStorage.removeItem(LS_CAMERA_KEY)
	}

	if (
		selectedSpeaker &&
		!availableSpeakers.find(device => device.deviceId === selectedSpeaker)
	) {
		selectedSpeaker = undefined
		window.localStorage.removeItem(LS_SPEAKER_KEY)
	}

	if (
		selectedMicrophone &&
		!availableMicrophones.find(device => device.deviceId === selectedMicrophone)
	) {
		selectedMicrophone = undefined
		window.localStorage.removeItem(LS_MICROPHONE_KEY)
	}
}

const stopStream = (): void => {
	allStreams.forEach(stream => {
		stream?.getTracks().forEach(track => track.stop())
	})
	allStreams.clear()

	stream?.getTracks().forEach(track => track.stop())
}

const testStream = async (device?: MediaDeviceInfo): Promise<void> => {
	stopStream()

	const testStream = await navigator.mediaDevices.getUserMedia({
		audio: true,
		video: device ? { deviceId: { exact: device.deviceId } } : false,
	})

	await listDevices()

	testStream.getTracks().forEach(track => track.stop())

	stopStream()
}

const startStream = async () => {
	startAsyncOperation('startStream')
	syncDevices()
	stopStream()

	const device = availableCameras.find(
		device => device.deviceId === selectedCamera
	)

	if (!device || !selectedCamera) {
		return undefined
	}

	if (!permissionsGranted) {
		await askForPermission()
	}

	try {
		const startingStream = await navigator.mediaDevices.getUserMedia({
			video: { deviceId: { exact: selectedCamera } },
		})
		allStreams.set(selectedCamera, startingStream)
		stream = startingStream
		error = undefined
	} catch (e) {
		console.log('MediaDevices | Starting the stream failed')
		console.log(e)
		failedDevices.push(device)
		error = 'CAMERA_FAILED_TO_START'
		syncDevices()
		stopStream()
	}

	finishAsyncOperation('startStream')
}

const askForPermission = async () => {
	startAsyncOperation('askForPermission')
	checkingPermissions = true
	let timedOut = false

	const timeout = setTimeout(() => {
		timedOut = true
	}, TIME_FOR_USER_DECISION)

	try {
		await testStream()

		if (timedOut) {
			error = 'ASKING_FOR_PERMISSIONS_TIMED_OUT'
			permissionsGranted = false
		} else {
			error = undefined
			permissionsGranted = true
		}
	} catch (e) {
		console.log('MediaDevices | Asking user for permission failed')

		console.log(e)

		error =
			e instanceof Error && e.name === 'NotReadableError'
				? 'DEVICE_IS_IN_USE'
				: 'ASKING_FOR_PERMISSIONS_FAILED'

		permissionsGranted = false
	}

	clearTimeout(timeout)
	checkingPermissions = false
	finishAsyncOperation('askForPermission')
}

const updateCamera = (deviceId?: string) => {
	selectedCamera = deviceId
	if (deviceId) {
		window.localStorage.setItem(LS_CAMERA_KEY, deviceId)
	} else {
		window.localStorage.removeItem(LS_CAMERA_KEY)
	}
	finishAsyncOperation('selectCamera')
}

const selectCamera = async (deviceId: string): Promise<void> => {
	startAsyncOperation('selectCamera')
	stopStream()

	const device = allDevices.find(device => device.deviceId === deviceId)
	const failed = failedDevices.find(device => device.deviceId === deviceId)

	if (!device || failed) {
		return updateCamera()
	}

	try {
		await testStream(device)
		updateCamera(deviceId)
	} catch (e) {
		console.log('MediaDevices | Selecting the camera failed')
		console.log(e)
		failedDevices.push(device)
		error = 'CAMERA_FAILED_TO_START'
		updateCamera()
		syncDevices()
	}
}

const updateSpeaker = (deviceId?: string) => {
	selectedSpeaker = deviceId
	if (deviceId) {
		window.localStorage.setItem(LS_SPEAKER_KEY, deviceId)
	} else {
		window.localStorage.removeItem(LS_SPEAKER_KEY)
	}
	notifySubscribers('selectSpeaker')
	return undefined
}

const selectSpeaker = async (deviceId: string): Promise<void> => {
	const device = allDevices.find(device => device.deviceId === deviceId)
	const failed = failedDevices.find(device => device.deviceId === deviceId)

	if (!device || failed) {
		return updateSpeaker()
	}

	updateSpeaker(deviceId)
}

const updateMicrophone = (deviceId?: string) => {
	selectedMicrophone = deviceId
	if (deviceId) {
		window.localStorage.setItem(LS_MICROPHONE_KEY, deviceId)
	} else {
		window.localStorage.removeItem(LS_MICROPHONE_KEY)
	}
	notifySubscribers('selectMicrophone')
	return undefined
}

const selectMicrophone = async (deviceId: string): Promise<void> => {
	const device = allDevices.find(device => device.deviceId === deviceId)
	const failed = failedDevices.find(device => device.deviceId === deviceId)

	if (!device || failed) {
		return updateMicrophone()
	}

	updateMicrophone(deviceId)
}

const listDevices = async () => {
	allDevices = (await navigator.mediaDevices.enumerateDevices()).filter(
		mdi => mdi.deviceId
	)
	syncDevices()
}

window.navigator.mediaDevices.addEventListener('devicechange', async () => {
	startAsyncOperation('devicechange')
	await listDevices()
	finishAsyncOperation('devicechange')
})

const useRerender = () => {
	const [, setRerender] = useState(0)
	const callback = () => setRerender(prev => prev + 1)

	useEffect(() => {
		return subscribe(callback)
	}, [])
}

export const useMediaDevices = (): UseMediaDevices => {
	useRerender()

	return {
		uiReady,
		checkingPermissions,
		permissionsGranted,
		askForPermission,

		error,

		stream,
		stopStream,
		startStream,

		selectedCamera,
		selectedSpeaker,
		selectedMicrophone,

		selectCamera,
		selectMicrophone,
		selectSpeaker,

		availableDevices,
		availableCameras,
		availableSpeakers,
		availableMicrophones,
	}
}
