import { FC, useState } from 'react'
import { colors, intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { CameraOff } from 'room/common/presentation/icon/camera-off'
import FirstStepImage from './images/step_first.png'
import {
	SafariNotSupportedInfo,
	useDetectSafari,
} from './safari-not-supported-info'
import { MediaDevicesError } from 'utils/hooks/use-media-devices'

type Props = {
	onAskForPermission: () => unknown
	error?: MediaDevicesError | null
}

const RequestBox = styled.div`
	position: relative;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 5px;
	background: ${colors.heather};

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: 57%;
	}
`

const RequestBoxIcon = styled.div`
	position: absolute;
	left: 50%;
	top: 42%;
	transform: translate(-50%, -50%);
	color: ${colors.white};

	svg {
		width: 48px;
	}
`

const RequestBoxText = styled.div`
	position: absolute;
	left: 20px;
	bottom: 20px;
	width: calc(100% - 40px);
	background: ${colors.darkBlue};
	border-radius: 5px;
	padding: 10px 20px;
	color: ${colors.white};
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
`

const Content = styled.div``

const Text = styled.div`
	margin-bottom: 30px;
	font-size: 18px;
	line-height: 28px;
	margin-top: 20px;

	b {
		font-weight: 500;
	}
`

const Steps = styled.div`
	border-top: 1px solid ${colors.borderGray};
	margin-bottom: 20px;
`

const Step = styled.div`
	display: flex;
	border-bottom: 1px solid ${colors.borderGray};
	padding: 30px 0 20px;
`

const StepContent = styled.div`
	padding-left: 15px;
	flex: 1;
`

const StepNumber = styled.div`
	color: ${colors.indigoBlue};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	flex: 0 0 40px;
	font-size: 16px;
	line-height: 1;
	border-radius: 50%;
	background: ${colors.indigoBlue10};
	margin-top: -10px;
`

const StepText = styled.div`
	font-size: 16px;
	line-height: 20px;
`

const StepBox = styled.div`
	box-shadow: 0 5px 15px 0 rgba(19, 16, 13, 0.1);
	border: 1px solid ${colors.brightGray};
	padding: 10px;
	border-radius: 5px;
	margin-top: 20px;
`

const CheckBox = styled.div`
	position: relative;
	display: flex;
	padding-left: 30px;
	font-size: 14px;
	line-height: 20px;

	&:before,
	&:after {
		content: '';
		position: absolute;
	}

	&:before {
		width: 16px;
		height: 16px;
		background: ${colors.indigoBlue};
		border-radius: 50%;
		left: 5px;
		top: 0;
	}

	&:after {
		position: absolute;
		left: 8px;
		top: 3px;
		width: 10px;
		height: 10px;
		border: 2px solid ${colors.white};
		border-radius: 50%;
	}
`

const AskForPermissionView: FC<Props> = ({ error, onAskForPermission }) => {
	const [showInstructions, setShowInstructions] = useState(false)

	const isSafari = useDetectSafari()
	const cameraUsedInOtherApp = error === 'DEVICE_IS_IN_USE'

	return (
		<Content>
			{cameraUsedInOtherApp ? (
				<CameraUsedInOtherApp
					isSafari={isSafari}
					onAskForPermission={onAskForPermission}
				/>
			) : showInstructions ? (
				<AllowCameraInstructions
					isSafari={isSafari}
					onAskForPermission={onAskForPermission}
				/>
			) : (
				<NoCameraPermission
					isSafari={isSafari}
					onAskForPermission={() => {
						setShowInstructions(true)
						onAskForPermission()
					}}
				/>
			)}
		</Content>
	)
}

const NoCameraPermission: FC<{
	isSafari: boolean
	onAskForPermission: () => unknown
}> = ({ isSafari, onAskForPermission }) => (
	<div style={{ textAlign: 'center' }}>
		<RequestBox>
			<RequestBoxIcon>
				<CameraOff />
			</RequestBoxIcon>
			<RequestBoxText>
				<intl.Translate>
					hardware_check.ask_for_permission_view_1_box_text
				</intl.Translate>
			</RequestBoxText>
		</RequestBox>
		{isSafari && <SafariNotSupportedInfo />}
		<Text>
			<b>
				<intl.Translate>
					hardware_check.ask_for_permission_view_1_bold_text
				</intl.Translate>
			</b>
			<intl.Translate>
				hardware_check.ask_for_permission_view_1_text
			</intl.Translate>
		</Text>
		<ui.ButtonPrimary onClick={onAskForPermission}>
			<intl.Translate>
				hardware_check.ask_for_permission_view_1_button
			</intl.Translate>
		</ui.ButtonPrimary>
	</div>
)

const CameraUsedInOtherApp: FC<{
	isSafari: boolean
	onAskForPermission: () => unknown
}> = ({ isSafari, onAskForPermission }) => (
	<div style={{ textAlign: 'center' }}>
		<RequestBox>
			<RequestBoxIcon>
				<CameraOff />
			</RequestBoxIcon>
			<RequestBoxText>
				<intl.Translate>hardware_check.camera_used_in_other_app</intl.Translate>
			</RequestBoxText>
		</RequestBox>
		{isSafari && <SafariNotSupportedInfo />}
		<Text>
			<intl.Translate>hardware_check.camera_used_details</intl.Translate>{' '}
			<b>
				<intl.Translate>hardware_check.camera_used_action</intl.Translate>
			</b>
		</Text>
		<ui.ButtonPrimary onClick={onAskForPermission}>
			<intl.Translate>hardware_check.camera_used_retry_button</intl.Translate>
		</ui.ButtonPrimary>
	</div>
)

const AllowCameraInstructions: FC<{
	isSafari: boolean
	onAskForPermission: () => unknown
}> = ({ isSafari, onAskForPermission }) => (
	<>
		<Text>
			<b>
				<intl.Translate>
					hardware_check.ask_for_permission_view_2_title
				</intl.Translate>
			</b>
		</Text>
		<Steps>
			<Step>
				<StepNumber>1</StepNumber>
				<StepContent>
					<StepText>
						<intl.Translate>hardware_check.step_1_title</intl.Translate>
					</StepText>
					<StepBox>
						<img src={FirstStepImage} alt="" />
					</StepBox>
				</StepContent>
			</Step>
			<Step>
				<StepNumber>2</StepNumber>
				<StepContent>
					<StepText>
						<intl.Translate>hardware_check.step_2_title</intl.Translate>
					</StepText>
					<StepBox>
						<CheckBox>
							<intl.Translate>hardware_check.step_2_content</intl.Translate>
						</CheckBox>
					</StepBox>
				</StepContent>
			</Step>
			<Step>
				<StepNumber>3</StepNumber>
				<StepContent>
					<StepText>
						<intl.Translate>hardware_check.step_3_title</intl.Translate>
					</StepText>
				</StepContent>
			</Step>
		</Steps>
		{isSafari && <SafariNotSupportedInfo />}
		<div style={{ marginTop: '20px', textAlign: 'right' }}>
			<ui.ButtonMinor onClick={onAskForPermission}>
				<intl.Translate>
					hardware_check.ask_for_permission_view_2_button
				</intl.Translate>
			</ui.ButtonMinor>
		</div>
	</>
)

export default AskForPermissionView
