export { default as face } from './face.png'
export { default as error } from './error.svg'
export { default as warning } from './warning.svg'
export { default as success } from './success.svg'
export { default as invisiblePassword } from './invisible-password.svg'
export { default as visiblePassword } from './visible-password.svg'
export { default as beeldayLogo } from './beelday-logo.svg'
export { default as beeldayLogoWhite } from './beelday-logo-white.svg'
export { default as beeldayLogoCloud } from './beelday-logo-cloud.svg'
export { default as time } from './time.svg'
export { default as calendar } from './calendar.svg'
export { default as calendarMobile } from './calendar.mobile.svg'
export { default as agenda } from './agenda.svg'
export { default as files } from './files.svg'
export { default as boardlet } from './boardlet.svg'
export { default as mobileBeeldayBackground } from './mobile-beelday-logo-background.svg'
export { default as arrowHeather } from './arrow-heather.svg'
export { default as empty } from './empty.svg'
export { default as search } from './search.svg'
export { default as contextMenu } from './context-menu.svg'
export { default as menu } from './menu.svg'
export { default as pimple } from './pimple.svg'
export { default as bubblesBg } from './bubbles-bg.svg'
export { default as info } from './info.svg'
export { default as backArrow } from './back-arrow.svg'
export { default as emoji } from './emoji.svg'
export { default as send } from './send.svg'
export { default as like } from './like.svg'
export { default as pdf } from './pdf.svg'
export { default as avi } from './avi.svg'
export { default as doc } from './pdf.svg'
export { default as eps } from './eps.svg'
export { default as gif } from './gif.svg'
export { default as png } from './png.svg'
export { default as ppt } from './ppt.svg'
export { default as txt } from './txt.svg'
export { default as xls } from './xls.svg'
export { default as zip } from './zip.svg'
export { default as unknown } from './unknown.svg'
export { default as reply } from './reply.svg'
export { default as likeOutline } from './like-outline.svg'
export { default as knowledgeCheckTemplateIcon } from './knowledge-check-template-icon.svg'
export { default as groupingTemplateIcon } from './grouping-template-icon.svg'
export { default as groupingTemplateCircleIcon } from './grouping-template-circle-icon.svg'
export { default as pollingTemplateCircleIcon } from './polling-template-circle-icon.svg'
export { default as knowledgeCheckTemplateCircleIcon } from './knowledge-check-template-circle-icon.svg'
export { default as whiteboardTemplateCircleIcon } from './whiteboard-template-icon.svg'
export { default as notepadTemplateCircleIcon } from './notepad-template-icon.svg'
export { default as pollingTemplateIcon } from './polling-template-icon.svg'
export { default as slidesTemplateIcon } from './slide-template-icon.svg'
export { default as slidesTemplateCircleIcon } from './slide-template-circle-icon.svg'
export { default as trainingTemplateIcon } from './template-icon.svg'
export { default as noMediaIcon } from './no-media.svg'
export { default as plus } from './plus.svg'
export { default as boardletWithPosts } from './boardlet-with-posts.svg'
export { default as multipleChoiceImage } from './multiple-choice-image.png'
export { default as standardListImage } from './standard-list-image.png'
export { default as wordCloudImage } from './word-cloud-image.png'
export { default as folder } from './folder.svg'
