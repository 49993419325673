import { intl } from '@beelday/common'
import { Config } from 'common/config'
import { useCallback } from 'react'
import {
	MenuToggleItem,
	PopupMenu,
	PopupMenuElement,
	PopupNestedMenu,
} from 'room/common/presentation/menu'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import useMicApi from './microphone-api'
import MicrophoneIcon from './microphone-icon'
import MicrophoneMutedIcon from './microphone-muted-icon'
import { useSelector } from 'react-redux'
import { selectCurrentUserMuted } from 'interaction-scheme/redux'
import { useMediaDevices } from 'utils/hooks/use-media-devices'
import { selectEnabledFeatures } from 'room/common/redux'

const MicMenuItem: React.FC = () => {
	const { availableMicrophones, selectedMicrophone, selectMicrophone } =
		useMediaDevices()

	const api = useMicApi(Config.beeldayBackendUrl)
	const address = useAssertedJoinedRoomAddress()
	const user = useAssertedWorkflowUser()
	const enabledFeatures = useSelector(selectEnabledFeatures)

	const isFeatureEnabled = enabledFeatures.includes('MicrophoneType')
	const isMuteMic = useSelector(selectCurrentUserMuted)

	const toggleMic = useCallback(() => {
		if (!isMuteMic) {
			api.muteMic(address, user.id)
		} else {
			api.unmuteMic(address, user.id)
		}
	}, [isMuteMic, api, address, user])

	const currentMic = availableMicrophones.find(
		mic => mic.deviceId === selectedMicrophone
	)

	if (!isFeatureEnabled) return null
	return (
		<MenuToggleItem
			active={isMuteMic}
			activeIcon={<MicrophoneMutedIcon />}
			icon={<MicrophoneIcon />}
			onClick={toggleMic}
			label={<intl.Translate>room_menu.microphone</intl.Translate>}
		>
			{currentMic ? (
				<PopupMenu>
					<PopupMenuElement text={currentMic.label}>
						<PopupNestedMenu>
							<>
								{availableMicrophones.map(mic => (
									<PopupMenuElement
										key={mic.deviceId}
										text={mic.label}
										active={selectedMicrophone === mic.deviceId}
										onClick={() => selectMicrophone(mic.deviceId)}
									/>
								))}
							</>
						</PopupNestedMenu>
					</PopupMenuElement>
				</PopupMenu>
			) : null}
		</MenuToggleItem>
	)
}

export default MicMenuItem
