import { UserRoles } from './security-models'

export function isTrainer(roles: Array<UserRoles>): boolean {
	return roles.includes(UserRoles.UPPER_ECHELON)
}

export function isCompanyAdmin(roles: Array<UserRoles>): boolean {
	return roles.includes(UserRoles.COMPANY_ADMIN)
}

export function isAccountOwner(roles: Array<UserRoles>): boolean {
	return roles.includes(UserRoles.ACCOUNT_OWNER)
}
